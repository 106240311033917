import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCancel32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="cancel-32_svg__a" d="M23.372 8 16 15.372 8.628 8 8 8.628 15.372 16 8 23.372l.628.628L16 16.628 23.372 24l.628-.628L16.628 16 24 8.628z" /></defs><g fillRule="evenodd"><mask id="cancel-32_svg__b" fill="#fff"><use xlinkHref="#cancel-32_svg__a" /></mask><g mask="url(#cancel-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgCancel32);
export default Memo;